import styled from "styled-components"
import { BgImage } from "gbimage-bridge"

const FixedHeroImage = styled(BgImage)`
  background-size: auto, cover;
  background-attachment: scroll;
  height: 700px;
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed !important;
    &&:before {
      background-attachment: fixed !important;
    }
    &&:after {
      background-attachment: fixed !important;
    }
  }
`

export default FixedHeroImage
